// This is a singleton variant of the standard Landing Page design,
// with a hero image and text, and a grid of Resource Library Items

import React, { useMemo, useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import Select from 'react-select'

import SEO from '../components/SEO'
import Layout from '../components/Layout'
import { paginationChange } from '../util/helper'
import PaginatonControl from '../components/PaginatonControl'
import WithLocation from '../util/WithLocation'
import LandingPageHero from '../components/Heroes/LandingPageHero'
import GridBlock from '../components/ContentBlocks/GridBlock'
import * as Styled from '../components/styles/ResourceLibrary.styles'
import {
  FilterIndicator as StyledFilterIndicator,
  FilterIndicatorBar as StyledFilterIndicatorBar,
  FilterIndicatorText,
  CloseXContainer,
  ShowingResultsFor,
} from '../components/styles/MediaLibrary.styles'
import { RegionContainer } from '../components/styles/Utility.styles'
import { FilterCloseX } from '../components/Icons'
import FilterRow from '../components/FilterRow'
import { DROPDOWN_STYLES } from '../util/constants'
import { MediaLibrarySearchField as ResourceLibrarySearchField } from '../components/styles/FilterRow.styles'
import SliceRenderer from '../components/ContentBlocks/SliceRenderer'
import BodyContentBlock from '../components/ContentBlocks/BodyContentBlock'
import {
  FilterLabelSelectPair as StyledFilterLabelSelectPair,
  FilterLabel as StyledFilterLabel,
} from '../components/styles/ProductDirectory.styles'
import { BottomLineGoogleAd } from '../components/styles/GoogleAdsCommon.styles'

const sortOptions = [
  { value: 'A->Z', label: 'A\u2192Z' },
  { value: 'Z->A', label: 'Z\u2192A' },
]

const ITEMS_PER_PAGE = 15

const getResourceTopicOptions = topics => {
  const options = []
  topics.map(topic => {
    options.push({ value: topic.node.data.topic, label: topic.node.data.topic })
  })
  return options.sort((a, b) => a.label.localeCompare(b.label))
}

const FilterIndicator = ({ filter, clearFilter, isSearch = false }) => (
  <>
    {filter && (
      <StyledFilterIndicator>
        <FilterIndicatorText>
          {!isSearch
            ? filter
            : `${String.fromCharCode(8220)}${filter}${String.fromCharCode(
                8220
              )}`}
        </FilterIndicatorText>
        <CloseXContainer onClick={e => clearFilter(filter, e)}>
          <FilterCloseX />
        </CloseXContainer>
      </StyledFilterIndicator>
    )}
  </>
)

const FilterIndicatorBar = ({ topicFilter, searchInput, clearFilter }) => (
  <StyledFilterIndicatorBar isEnabled={topicFilter || searchInput}>
    <ShowingResultsFor>Showing results for: </ShowingResultsFor>
    <FilterIndicator filter={topicFilter} clearFilter={clearFilter} />
    <FilterIndicator filter={searchInput} clearFilter={clearFilter} isSearch />
  </StyledFilterIndicatorBar>
)

const ResourceLibraryGrid = ({
  gridItems,
  title,
  topics = [],
  showFilters = false,
  firstItemForPage = null,
  lastItemForPage = null,
  paginationChangeAction = null,
  setFilteredItemsCount,
}) => {
  const [topicFilter, setTopicFilter] = useState(null)
  const [searchInput, setSearchInput] = useState('')
  const [filterApplied, setFilterApplied] = useState(false)
  const [sortOption, setSortOption] = useState(sortOptions[0])
  const [paginatedGrid, setPaginatedGrid] = useState([])

  const filteredItems = gridItems.filter(item => {
    const topics = item.topics?.map(t =>
      t.topic?.document?.data?.topic?.toUpperCase()
    )
    const matchesTopic =
      !topicFilter || (topics && topics.includes(topicFilter.toUpperCase()))
    const matchesSearch =
      !searchInput ||
      item.title.text.toUpperCase().includes(searchInput.toUpperCase()) ||
      item.summary?.text?.toUpperCase().includes(searchInput.toUpperCase()) ||
      item.body_copy.text.toUpperCase().includes(searchInput.toUpperCase())
    return matchesTopic && matchesSearch
  })

  const sortedItems = filteredItems.sort((a, b) => {
    if (sortOption.value === 'A->Z') {
      return a.title.text.localeCompare(b.title.text)
    } else if (sortOption.value === 'Z->A') {
      return b.title.text.localeCompare(a.title.text)
    }
  })

  const clearFilter = (whichFilter, e) => {
    e.preventDefault()
    if (whichFilter === topicFilter) {
      setTopicFilter(null)
    } else if (whichFilter === searchInput) {
      setSearchInput('')
    }
  }

  const gridForPage = showFilters
    ? sortedItems.slice(firstItemForPage, lastItemForPage)
    : sortedItems

  const gridData = useMemo(
    () => ({
      primary: {
        layout_type: '3 Column',
      },
      items: gridForPage,
    }),
    [gridForPage]
  )

  useEffect(() => {
    setFilterApplied(topicFilter || searchInput !== '')
  })

  useEffect(() => {
    if (showFilters) setFilteredItemsCount(filteredItems.length)
  }, [filteredItems, showFilters])

  return (
    <>
      <Styled.GridHeaderContainer>
        <h2>{title}</h2>
      </Styled.GridHeaderContainer>

      {/* <pre>{JSON.stringify(gridItems, false, 2)}</pre> */}
      {showFilters && (
        <Styled.FilterRowContainer>
          <FilterRow>
            <StyledFilterLabelSelectPair>
              <StyledFilterLabel>Topic</StyledFilterLabel>
              <Select
                // placeholder="Topic"
                options={getResourceTopicOptions(topics)}
                value={
                  topicFilter
                    ? { value: topicFilter, label: topicFilter }
                    : null
                }
                styles={DROPDOWN_STYLES}
                width="240px"
                marginRight="10px"
                onChange={e =>
                  e?.value ? setTopicFilter(e.value) : setTopicFilter(null)
                }
              />
            </StyledFilterLabelSelectPair>
            <StyledFilterLabelSelectPair>
              <StyledFilterLabel>Sort</StyledFilterLabel>
              <Select
                options={sortOptions}
                value={sortOption}
                defaultValue={sortOptions[0]}
                styles={DROPDOWN_STYLES}
                width="180px"
                marginRight="10px"
                onChange={e =>
                  setSortOption({ value: e.value, label: e.label })
                }
              />
            </StyledFilterLabelSelectPair>
            <StyledFilterLabelSelectPair>
              <StyledFilterLabel>Search</StyledFilterLabel>
              <form>
                <ResourceLibrarySearchField
                  placeholder="Search"
                  onChange={e => setSearchInput(e.target.value)}
                  value={searchInput}
                  name="search"
                />
              </form>
            </StyledFilterLabelSelectPair>
          </FilterRow>
        </Styled.FilterRowContainer>
      )}

      {showFilters && filterApplied && (
        <Styled.FilterIndicatorBarContainer>
          <FilterIndicatorBar
            topicFilter={topicFilter}
            searchInput={searchInput}
            clearFilter={clearFilter}
          />
        </Styled.FilterIndicatorBarContainer>
      )}

      <GridBlock data={gridData} />
    </>
  )
}

const ResourceLibraryLandingPage = ({ data }) => {
  const [filteredItemsCount, setFilteredItemsCount] = useState(null)
  const [firstItemForPage, setFirstItemForPage] = useState(0)
  const [lastItemForPage, setLastItemForPage] = useState(ITEMS_PER_PAGE)

  const doc = data.prismicResourceLibraryPage.data
  const topics = data.allPrismicResourceLibraryTopic.edges

  if (!doc) {
    return null
  }

  const twitterImage = doc.twitter_image?.url ? doc.twitter_image : null
  const facebookImage = doc.facebook_image?.url ? doc.facebook_image : null
  const seoTitle = doc.meta_title?.text
    ? doc.meta_title.text
    : 'Resource Library'
  const seo_embed_code = doc.seo_embed_code?.text ?? null
  const meta_description = doc.meta_description
  const meta_keywords = doc.meta_keywords

  const settingsNode = data.prismicGeneralSiteSettings.data
  const adBlockId = settingsNode.subpages_google_ad_tag?.document.id

  const allResources = data.allPrismicResourceLibraryItem.edges

  const pageTitle = doc.title?.text ?? 'Resource Library'
  const heroText = doc.hero_text?.text ?? ''
  const uid = data.prismicResourceLibraryPage.uid

  const hasBodyContent = doc.body_copy && doc.body_copy.richText

  // Minor hack to be able to use the BodyContentBlock component by restructuring the data to match
  const highlightCaptionData = useMemo(
    () => ({
      body_copy: {
        richText: doc.highlight_resource_items_caption?.richText ?? null,
      },
    }),
    [doc.highlight_resource_items_caption]
  )

  // Another minor hack to be able to use the GridBlock component by restructuring the data to match
  const highlightItems = useMemo(
    () =>
      doc.highlight_resource_items.map(item => {
        const itemData = item.highlight_resource_library_item.document.data
        const itemImage =
          item.highlight_resource_library_item.document.data.image ?? {}
        return {
          title: {
            text: itemData.page_title?.text ?? '',
          },
          summary: {
            richText: itemData.summary?.richText ?? null,
            text: itemData.summary?.text ?? null,
          },
          body_copy: {
            text: itemData.body_copy?.text ?? '',
          },
          image: itemImage
            ? {
                alt: itemImage.alt,
                url: itemImage.thumbnails?.resource_library?.url ?? null,
                thumbnails: {
                  mobile: {
                    url:
                      itemImage.thumbnails?.resource_library_mobile?.url ??
                      null,
                  },
                },
              }
            : null,
          cta_link_text:
            itemData.resource_library_cta_text?.text &&
            itemData.resource_library_cta_text.text.trim() !== ''
              ? itemData.resource_library_cta_text.text
              : 'Get your guide',
          cta_link: {
            link_type: 'Document',
            type: 'resource_library_item',
            uid: item.highlight_resource_library_item.uid,
            target: '_blank',
          },
        }
      }),
    [doc.highlight_resource_items]
  )

  const allResourceData = useMemo(
    () =>
      allResources.map(({ node }) => {
        const resourceData = node.data
        const resourceImage = node.data.image
        return {
          title: {
            text: resourceData.page_title?.text ?? '',
          },
          summary: {
            richText: resourceData.summary?.richText ?? null,
            text: resourceData.summary?.text ?? null,
          },
          body_copy: {
            text: resourceData.body_copy?.text ?? '',
          },
          topics: resourceData.topics,
          image: resourceImage
            ? {
                alt: resourceImage.alt,
                url: resourceImage.thumbnails?.resource_library?.url ?? null,
                thumbnails: {
                  mobile: {
                    url:
                      resourceImage.thumbnails?.resource_library_mobile?.url ??
                      null,
                  },
                },
              }
            : null,
          cta_link_text:
            resourceData.resource_library_cta_text?.text &&
            resourceData.resource_library_cta_text.text.trim() !== ''
              ? resourceData.resource_library_cta_text.text
              : 'Get your guide',
          cta_link: {
            link_type: 'Document',
            type: 'resource_library_item',
            uid: node.uid,
            target: '_blank',
          },
        }
      }),
    [allResources]
  )

  const hasHighlights = doc.highlight_resource_items.length > 0
  const hasResources = allResources.length > 0
  const gridTitle =
    doc.resource_list_heading?.text &&
    doc.resource_list_heading.text.trim() !== ''
      ? doc.resource_list_heading.text
      : 'Discover All of Our Psoriatic Disease Resources'
  const highlightGridTitle =
    doc.highlight_resource_items_caption?.text &&
    doc.highlight_resource_items_caption?.text.trim() !== ''
      ? doc.highlight_resource_items_caption.text
      : 'Download One of These Popular Resources'

  const slices = doc.body

  return (
    <>
      <SEO
        seo={{
          description: meta_description,
          keywords: meta_keywords,
          title: seoTitle,
          twitter_image: twitterImage,
          facebook_image: facebookImage,
          seo_embed_code: seo_embed_code,
        }}
      />
      <Layout>
        <LandingPageHero
          pageTitle={pageTitle}
          heroText={heroText}
          heroImage={doc.hero_image}
          heroGraphic={'Disease / Treatment'}
          uid={uid}
          halfHeight={true}
        />
        {hasBodyContent && <BodyContentBlock data={doc} />}
        {hasHighlights && (
          <ResourceLibraryGrid
            gridItems={highlightItems}
            title={highlightGridTitle}
          />
        )}
        {hasResources && (
          <>
            <ResourceLibraryGrid
              gridItems={allResourceData}
              title={gridTitle}
              topics={topics}
              showFilters={true}
              firstItemForPage={firstItemForPage}
              lastItemForPage={lastItemForPage}
              paginationChangeAction={paginationChange}
              setFilteredItemsCount={setFilteredItemsCount} // Ensure this prop is passed
            />
            <Styled.PaginationContainer>
              <PaginatonControl
                itemCount={
                  filteredItemsCount
                    ? filteredItemsCount
                    : allResourceData.length
                }
                itemsPerPage={ITEMS_PER_PAGE}
                setFirstItemForPage={setFirstItemForPage}
                setLastItemForPage={setLastItemForPage}
                paginationChangeAction={paginationChange}
              />
            </Styled.PaginationContainer>
          </>
        )}
        {slices &&
          slices.map(block => <SliceRenderer key={block.id} block={block} />)}
        {adBlockId && <BottomLineGoogleAd id={adBlockId} />}
      </Layout>
    </>
  )
}

export const query = graphql`
  {
    prismicGeneralSiteSettings {
      data {
        subpages_google_ad_tag {
          document {
            ... on PrismicGoogleAdTag {
              id
            }
          }
        }
      }
    }
    prismicResourceLibraryPage {
      uid
      data {
        meta_title {
          text
        }
        meta_description
        meta_keywords
        seo_embed_code {
          text
        }
        facebook_image {
          url
        }
        twitter_image {
          url
        }
        hero_image {
          url
          thumbnails {
            mobile {
              url
            }
          }
        }
        title {
          text
        }
        hero_text {
          text
        }
        body_copy {
          richText
        }
        highlight_resource_items_caption {
          text
        }
        resource_list_heading {
          text
        }
        highlight_resource_items {
          highlight_resource_library_item {
            uid
            url
            document {
              ... on PrismicResourceLibraryItem {
                id
                data {
                  page_title {
                    text
                  }
                  resource_library_cta_text {
                    richText
                    text
                  }
                  summary {
                    richText
                    text
                  }
                  body_copy {
                    text
                  }
                  topics {
                    topic {
                      id
                      document {
                        ... on PrismicResourceLibraryTopic {
                          id
                          data {
                            topic
                          }
                        }
                      }
                    }
                  }
                  image {
                    alt
                    thumbnails {
                      resource_library {
                        url
                      }
                      resource_library_mobile {
                        url
                      }
                    }
                  }
                }
              }
            }
          }
        }
        body {
          ... on PrismicResourceLibraryPageDataBodyCtaBlock {
            id
            slice_type
            primary {
              cta_link_text
              layout_style
              cta_link {
                id
                url
                uid
                type
                link_type
              }
              body_text {
                richText
              }
              header {
                text
              }
              bottom_padding_adjustment
            }
          }
        }
      }
    }
    allPrismicResourceLibraryItem(
      sort: { fields: first_publication_date, order: DESC }
    ) {
      edges {
        node {
          id
          uid
          data {
            summary {
              richText
              text
            }
            page_title {
              text
            }
            resource_library_cta_text {
              text
            }
            body_copy {
              text
            }
            topics {
              topic {
                document {
                  ... on PrismicResourceLibraryTopic {
                    id
                    data {
                      topic
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    allPrismicResourceLibraryTopic {
      edges {
        node {
          id
          data {
            topic
          }
        }
      }
    }
  }
`

export default WithLocation(ResourceLibraryLandingPage)
